import React from 'react'
import PropTypes from 'prop-types'

function Review({
  text,
  meta
}) {
  return (
    <div className="review">
      <p className="quote">
        {`”${text}”`}
      </p>
      <p className="meta">
        {meta}
      </p>
    </div>
  )
}

Review.propTypes = {
  text: PropTypes.string.isRequired,
  meta: PropTypes.string.isRequired
}

export default Review
