const dark = {
  '--background-color': '#000',
  '--heading-text-color': '#FFFFFF',
  '--body-text-color': '#939BAC',
  '--text-link-color': '#FFFFFF',
  '--right-background-image': 'linear-gradient(180deg, #000000 0%, #1C1C1E 100%)',
  '--copy-right-color': '#939BAC',
  '--review-background-image': 'linear-gradient(90deg, #161618 0%, #161618 100%, #161618 100%)',
  '--footer-background-image': 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 50%)',
  '--new-features-background-image': 'linear-gradient(90deg, rgba(29,42,50,0.51) 0%, #000000 100%, #000000 100%)',
  '--logo-color': '#FFF',
  '--text-link-underline-color': 'rgba(0, 0, 0, .3)'
}

const light = {
  '--background-color': '#fff',
  '--heading-text-color': '#3C4753',
  '--body-text-color': '#6E7A91',
  '--text-link-color': '#6E7A91',
  '--right-background-image': 'linear-gradient(180deg, #9CD8FE 0%, #9CD8FE 100%)',
  '--copy-right-color': '#3C4753',
  '--review-background-image': 'linear-gradient(90deg, rgba(36, 43, 47, .03) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%)',
  '--footer-background-image': 'linear-gradient(180deg, rgba(255, 255, 255, .5) 0%, rgba(255, 255, 255, .98) 100%)',
  '--new-features-background-image': 'linear-gradient(90deg, rgba(36,43,47,0.03) 0%, rgba(0,0,0,0.00) 100%, rgba(0,0,0,0.00) 100%)',
  '--logo-color': '#362825',
  '--text-link-underline-color': 'rgba(255, 255, 255, .5)'
}

export { dark, light }
