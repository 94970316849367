import React from 'react'

// Utils
import { connectEmitter } from '../utils/handleEmitter'

function Footer() {
  function openModal() {
    window.___emitter.emit('toggleModal', { isOpen: true })
  }

  return (
    <footer className="footer">
      <ul>
        <li>
          <a href="https://submit.bobbyapp.co">
            Submit
          </a>
        </li>
        <li>
          <a href="https://headwayapp.co/bobby-release-notes">
            Changelog
          </a>
        </li>
        <li>
          <a href="mailto:info@bobbyapp.co">Email Support</a>
        </li>
        <li>
          <a href="https://drive.google.com/drive/folders/1KsV8PfM36VHtffqJrXdF7nIm5jnGiEtt?usp=sharing" target="blank">Press</a>
        </li>
        <li onClick={() => openModal()}>Legal</li>
        <li>
          <a href="https://twitter.com/getbobbyapp" target="blank">Twitter</a>
        </li>
      </ul>
    </footer>
  )
}

export default connectEmitter(Footer)
