import React from 'react'

// Utils
import { connectEmitter } from '../utils/handleEmitter'

function Modal() {
  function closeModal() {
    window.___emitter.emit('toggleModal', { isOpen: false })
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <h1>Bobby - Legal information</h1>
        <p className="small">
          Bobby App has been created to help you track repeating subscriptions and requires its users to enter data regarding subscription payments. Bobby App will never ask you to enter payment credentials or other personalized data. Bobby doesn’t require any personal user details to be entered anywhere in the app and therefor is not connected to a user’s subscriptions. Bobby App and it’s creators can not be held responsible for (un)successfully notifying users of upcoming and/or due subscription payments. Bobby App and it’s creators can not be held responsible for any incorrect user data entered in the app. Any user generated data that is entered in Bobby App is neither saved on a server nor distributed. User generated data is exclusively stored on a user’s iDevice, though it will be included in an iCloud backup.
        </p>
        <svg
          className="cross"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => closeModal()}
        >
          <path
            d="M10.363 9l6.588-6.587a.963.963 0 1 0-1.364-1.364L9 7.637 2.413 1.049a.963.963 0 1 0-1.364 1.364L7.637 9l-6.588 6.587a.963.963 0 1 0 1.364 1.364L9 10.363l6.587 6.588a.963.963 0 1 0 1.364-1.364L10.363 9z"
            fill="#8A93A4"
            fillRule="evenodd"
          />
        </svg>
      </div>
    </div>
  )
}

export default connectEmitter(Modal)
