import React, {
  useState,
  useEffect,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

// Utils
import { connectEmitter } from '../utils/handleEmitter'

// Components
import Layout from '../layout'
import Footer from '../components/Footer'
import Logo from '../components/Logo'
import Review from '../components/Review'
import NewFeatures from '../components/NewFeatures'
import Feature from '../components/Feature'
import Modal from '../components/Modal'
import Announcement from '../components/Announcement'

// Images
import device from '../img/iphone-frame.png'
import appStoreButtonDark from '../img/app-store-dark.svg'
import appStoreButtonLight from '../img/app-store-light.svg'

const AppStoreButton = ({ isDarkTheme }) => {
  const src = isDarkTheme ? appStoreButtonDark : appStoreButtonLight

  return (
    <a target="blank" href="https://appsto.re/nl/xcwi_.i?utm_source=website&utm_medium=website&utm_campaign=v3_release">
      <img className="app-store" src={src} alt="app store button" />
    </a>
  )
}

AppStoreButton.propTypes = {
  isDarkTheme: PropTypes.bool
}

function IndexPage() {
  const [isModalOpen, setIsModalOpen] = useState()
  const [isExtraContentShown, setIsExtraContentShown] = useState(false)
  const [isAnimationShown, setIsAnimationShown] = useState(false)
  const [isDarkTheme, setIsDarkTheme] = useState(false)

  const refBobbyVideo = useRef(null)

  const newFeatures = [
    {
      title: 'Filter multiple categories',
      description: 'Filter using multiple categories to get even more insights into your subscriptions.',
      iconName: 'filter',
      href: 'https://headwayapp.co/bobby-release-notes'
    }
  ]

  const otherFeatures = [
    {
      title: 'Flexible payment cycles',
      iconName: 'moneyBill',
      description: 'Bobby lets you display your payment cycles per month and year now.'
    },
    {
      title: 'Re-order your subscriptions',
      iconName: 'arrowDouble',
      description: 'Change the order of subscriptions by dragging and dropping them in the order you prefer.'
    },
    {
      title: 'Passcode & Touch ID',
      iconName: 'faceId',
      description: 'Keep your subscription overview private by setting a passcode or Touch ID.'
    },
    {
      title: 'Foreign currency breakdown',
      iconName: 'coin',
      description: 'Get a breakdown of which foreign currency subscriptions make up your subscription total.'
    },
    {
      title: 'Weekly cycles',
      iconName: 'calendar',
      description: 'Get insights in your fixed costs. Manage your subscriptions and get notified when a bill is due.'
    }
  ]

  useEffect(() => {
    window.___emitter.on('toggleModal', ({ isOpen }) => setIsModalOpen(isOpen))

    window.___emitter.on('setIsDarkTheme', (value) => {
      setIsDarkTheme(value)
    })

    if (isExtraContentShown) {
      setTimeout(() => setIsAnimationShown(true), 100)
    }
  }, [isExtraContentShown])

  function onClickLearnButton() {
    setIsExtraContentShown(true)

    setTimeout(() => navigate('/#features'), 700)
  }

  return (
    <Layout>
      {
        isModalOpen && <Modal />
      }

      <Announcement
        title="Let us know which companies should be added to Bobby."
      />

      <div className="index-page">
        <div className="left">
          <img
            className={`stars ${isAnimationShown ? 'show' : ''}`}
            src={require('../img/stars.png')}
            alt="stars"
          />

          <div className="content">
            <Logo isDarkTheme={isDarkTheme} />

            <h1>Keep track of your subscriptions</h1>
            <p className="small">Get insights in your fixed costs. Manage your subscriptions and get notified when a bill is due.</p>

            <div className="buttons">
              <AppStoreButton isDarkTheme={isDarkTheme} />
              <button
                data-scroll
                type="button"
                className="learn-more"
                onClick={onClickLearnButton}
              >
                Learn More
              </button>
            </div>

            <div className="reviews">
              <Review
                text="Bobby is a beatifully designed way of keeping up with all my subscriptions!"
                meta="★★★★★ • Bobby User • App Store review"
              />
            </div>

            {
              isExtraContentShown && (
                <div
                  id="features"
                  className={`animated-content ${isAnimationShown ? 'show' : null}`}
                >
                  <NewFeatures data={newFeatures} />

                  <div className="other-features">
                    <h1>Other awesome features</h1>

                    {
                      otherFeatures.map(({
                        title,
                        iconName,
                        description
                      }, idx) => (
                        <Feature
                          key={idx}
                          title={title}
                          iconName={iconName}
                          description={description}
                        />
                      ))
                    }
                  </div>
                </div>
              )
            }
          </div>

          <Footer />
        </div>

        <div className="right">
          <div className="video">
            <video
              ref={refBobbyVideo}
              className="bobby-video"
              autoPlay="autoplay"
              loop="loop"
              preload="true"
              muted
              controls
              playsInline
            >
              <source src={require('../img/bobby-video-compressed.mp4')} type="video/mp4" />
            </video>

            <img className="device" src={device} alt="device" />
          </div>

          <span className="copy-right">
            {`© ${new Date().getFullYear()} Bobby`}
          </span>
        </div>
      </div>
    </Layout>
  )
}

export default connectEmitter(IndexPage)
