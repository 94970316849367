import React, { useEffect, useState } from 'react'

function Logo() {
  const [isDarkTheme, setIsDarkTheme] = useState(false)
  const [isHandleDown, setIsHandleDown] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      window.___emitter.on('setIsDarkTheme', (value) => {
        setIsDarkTheme(value)
      })
    }, 500)

    return () => (
      window.___emitter.off('setIsDarkTheme')
    )
  }, [isDarkTheme])

  function onLogoClick() {
    setIsHandleDown(true)

    setTimeout(() => {
      setIsHandleDown(false)
      window.___emitter.emit('toggleTheme')
    }, 500)

    return window.___emitter.off('setIsDarkTheme')
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="238"
      height="94"
      viewBox="0 0 238 94"
      className="logo"
      onClick={onLogoClick}
    >
      <defs>
        <circle id="oval-b" cx="341" cy="273" r="19" />
        <filter id="oval-a" width="302.6%" height="302.6%" x="-138.2%" y="-64.5%" filterUnits="objectBoundingBox">
          <feMorphology in="SourceAlpha" radius="3" result="shadowSpreadOuter1" />
          <feOffset dx="-14" dy="14" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="13.5" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.388357736 0" />
        </filter>
        <filter id="oval-c" width="273.7%" height="273.7%" x="-123.7%" y="-50%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1.5" />
          <feOffset dx="6" dy="-6" in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1" />
          <feColorMatrix in="shadowInnerInner1" result="shadowMatrixInner1" values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.244673295 0" />
          <feGaussianBlur in="SourceAlpha" result="shadowBlurInner2" stdDeviation="9" />
          <feOffset dx="6" dy="-6" in="shadowBlurInner2" result="shadowOffsetInner2" />
          <feComposite in="shadowOffsetInner2" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner2" />
          <feColorMatrix in="shadowInnerInner2" result="shadowMatrixInner2" values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.870902535 0" />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <linearGradient
          id="a"
          x1="32.77%"
          x2="70.031%"
          y1="15.674%"
          y2="85.258%"
        >
          <stop offset="0%" stopColor="#BA360A" />
          <stop offset="100%" stopColor="#961E03" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="50.245%"
          x2="50.245%"
          y1="14.091%"
          y2="99.325%"
        >
          <stop offset="0%" stopColor="#9E1E00" stopOpacity="0" />
          <stop offset="28.39%" stopColor="#901B00" stopOpacity="0.284" />
          <stop offset="100%" stopColor="#6E1500" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="53.667%"
          x2="46.942%"
          y1="-.059%"
          y2="72.663%"
        >
          <stop offset="0%" stopColor="#EE641A" />
          <stop offset="100%" stopColor="#BD2500" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="63.716%"
          x2="37.288%"
          y1="15.971%"
          y2="53.987%"
        >
          <stop offset="0%" stopColor="#FF985C" stopOpacity="0.7" />
          <stop offset="100%" stopColor="#FF985C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="50.61%"
          x2="48.163%"
          y1="98.468%"
          y2="5.388%"
        >
          <stop offset="0%" stopColor="#9E1E00" stopOpacity="0" />
          <stop offset="100%" stopColor="#9E1E00" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="118.326%"
          x2="18.902%"
          y1="140.408%"
          y2="-1.974%"
        >
          <stop offset="0%" stopColor="#C43616" />
          <stop offset="100%" stopColor="#EE641A" />
        </linearGradient>
        <linearGradient
          id="g"
          x1="77.146%"
          x2="11.943%"
          y1="32.903%"
          y2="80.413%"
        >
          <stop offset="0%" stopColor="#961E03" stopOpacity="0" />
          <stop offset="100%" stopColor="#961E03" stopOpacity="0.9" />
        </linearGradient>
        <linearGradient
          id="h"
          x1="21.493%"
          x2="54.458%"
          y1="9.084%"
          y2="106.697%"
        >
          <stop offset="0%" stopColor="#EE641A" />
          <stop offset="100%" stopColor="#C43616" />
        </linearGradient>
        <linearGradient
          id="i"
          x1="73.113%"
          x2="34.109%"
          y1="97.121%"
          y2="6.797%"
        >
          <stop offset="0%" stopColor="#C43616" />
          <stop offset="100%" stopColor="#EE641A" />
        </linearGradient>
        <linearGradient
          id="j"
          x1="6.323%"
          x2="62.262%"
          y1="90.038%"
          y2="24.742%"
        >
          <stop offset="0%" stopColor="#FF985C" stopOpacity="0.7" />
          <stop offset="100%" stopColor="#FF985C" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="k"
          cx="46.525%"
          cy="49.694%"
          r="56.211%"
          fx="46.525%"
          fy="49.694%"
          gradientTransform="matrix(.90518 0 0 1 .044 0)"
        >
          <stop offset="0%" stopColor="#78200C" stopOpacity="0.4" />
          <stop offset="100%" stopColor="#611200" />
        </radialGradient>
        <linearGradient
          id="l"
          x1="62.711%"
          x2="26.579%"
          y1="75.335%"
          y2="24.519%"
        >
          <stop offset="0%" stopColor="#47150A" />
          <stop offset="100%" stopColor="#943622" stopOpacity="0.3" />
        </linearGradient>
        <linearGradient
          id="m"
          x1="7.12%"
          x2="89.86%"
          y1="-19.095%"
          y2="116.644%"
        >
          <stop offset="0%" stopColor="#FFF" stopOpacity="0.7" />
          <stop offset="100%" stopColor="#FFF" stopOpacity="0.25" />
        </linearGradient>
        <linearGradient
          id="n"
          x1="24.903%"
          x2="51.439%"
          y1="-47.674%"
          y2="48.193%"
        >
          <stop offset="0%" stopColor="#E3E1E0" />
          <stop offset="64.05%" stopColor="#D3D1D0" />
          <stop offset="100%" stopColor="#E3E1E0" />
        </linearGradient>
        <linearGradient
          id="o"
          x1="34.466%"
          x2="67.138%"
          y1="6.353%"
          y2="78.005%"
        >
          <stop offset="0%" stopColor="#FF985C" stopOpacity="0.7" />
          <stop offset="100%" stopColor="#FF985C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="p"
          x1="67.691%"
          x2="17.534%"
          y1="67.959%"
          y2="16.363%"
        >
          <stop offset="0%" stopColor="#FF985C" stopOpacity="0" />
          <stop offset="100%" stopColor="#FF985C" stopOpacity="0.6" />
        </linearGradient>
        <linearGradient
          id="q"
          x1="1.817%"
          x2="56.608%"
          y1="64.499%"
          y2="49.502%"
        >
          <stop offset="0%" stopColor="#8A1A00" stopOpacity="0.2" />
          <stop offset="100%" stopColor="#8A1A00" />
        </linearGradient>
        <linearGradient
          id="r"
          x1="98.965%"
          x2="5.325%"
          y1="53.053%"
          y2="44.463%"
        >
          <stop offset="0%" stopColor="#CF4417" />
          <stop offset="100%" stopColor="#EE641A" />
        </linearGradient>
        <linearGradient
          id="s"
          x1="35.101%"
          x2="49.823%"
          y1="39.775%"
          y2="51.95%"
        >
          <stop offset="0%" stopColor="#FF985C" stopOpacity="0.7" />
          <stop offset="100%" stopColor="#FF985C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="t"
          x1="49.706%"
          x2="49.706%"
          y1="10.216%"
          y2="99.911%"
        >
          <stop offset="0%" stopColor="#9E1E00" stopOpacity="0" />
          <stop offset="100%" stopColor="#6E1500" />
        </linearGradient>
        <linearGradient
          id="u"
          x1="52.7%"
          x2="58.764%"
          y1="58.827%"
          y2="74.565%"
        >
          <stop offset="0%" stopColor="#9E1E00" stopOpacity="0" />
          <stop offset="100%" stopColor="#9E1E00" />
        </linearGradient>
        <linearGradient
          id="v"
          x1="-13.456%"
          x2="98.696%"
          y1="79.203%"
          y2="23.962%"
        >
          <stop offset="0%" stopColor="#961E03" stopOpacity="0" />
          <stop offset="100%" stopColor="#961E03" stopOpacity="0.9" />
        </linearGradient>
        <circle id="x" cx="19.96" cy="19" r="19" />
        <filter
          id="w"
          width="302.6%"
          height="302.6%"
          x="-138.2%"
          y="-64.5%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            radius="3"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dx="-14"
            dy="14"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="13.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.388357736 0"
          />
        </filter>
        <filter
          id="y"
          width="273.7%"
          height="273.7%"
          x="-123.7%"
          y="-50%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation="1.5"
          />
          <feOffset
            dx="6"
            dy="-6"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.189084353 0"
          />
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner2"
            stdDeviation="9"
          />
          <feOffset
            dx="6"
            dy="-6"
            in="shadowBlurInner2"
            result="shadowOffsetInner2"
          />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.236587631 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <path
          id="A"
          d="M19.893 26c2.97 0 5.067-1.598 5.067-4.004 0-1.858-1.24-3.066-3.158-3.233v-.056c1.579-.297 2.658-1.486 2.658-3.13 0-2.146-1.828-3.577-4.504-3.577-2.793 0-4.648 1.616-4.746 4.097h2.48c.071-1.17.9-1.942 2.15-1.942 1.338 0 2.078.716 2.078 1.803 0 1.105-.83 1.867-2.06 1.867h-1.562v2.025h1.588c1.454 0 2.337.743 2.337 1.97 0 1.133-.945 1.923-2.292 1.923-1.419 0-2.31-.744-2.391-1.877H14.96C15.085 24.337 16.985 26 19.893 26z"
        />
        <filter
          id="z"
          width="370%"
          height="292.9%"
          x="-135%"
          y="-96.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(0 6) translate(0 13.65) translate(116.158)">
          <path
            fill="url(#a)"
            d="M46.261 74.016l2.771-33.534-18.914 2.515c-3.373.598-8.433 5.509-8.433 11.258 0 10.66 7.71 14.611 7.71 14.611-9.035 0-11.204 1.797-11.204 3.354 0 .958.603 1.916 2.65 1.916l25.42-.12"
          />
          <path
            fill="url(#b)"
            d="M43.009 74.016H20.72c-2.048 0-2.65-.958-2.65-1.916 0-1.437 2.168-3.353 11.204-3.353h12.65l1.084 5.27z"
            opacity="0.5"
          />
          <path
            fill="url(#c)"
            d="M69.392 12.815C69.392 4.791 62.405.24 55.297.24c-7.59 0-15.3 4.43-15.3 14.491 0 15.57 18.914 23.116 18.914 37.608L46.14 73.897c10.24 0 22.528-14.013 22.528-28.505 0-14.133-9.397-21.438-9.397-28.385.12-5.39 5.18-8.024 10.12-4.192z"
          />
          <path
            fill="url(#d)"
            d="M55.297.24c-7.59 0-15.3 4.43-15.3 14.491 0 9.103 6.505 15.57 11.927 22.157.602.24.843 0 .24-1.077-3.493-5.87-5.18-12.337-5.18-17.726 0-8.623 5.06-12.216 10.602-12.216 4.698 0 9.758 1.916 11.806 6.826C69.392 4.791 62.405.24 55.297.24z"
            opacity="0.7"
          />
          <path
            fill="url(#e)"
            d="M68.79 45.392c0-14.133-9.397-21.438-9.397-28.385 0-2.635 1.325-4.67 3.132-5.51-2.891.84-5.3 4.193-4.94 8.145.603 6.467 6.386 15.33 6.386 26.828 0 16.887-10.12 25.27-16.505 27.427h.964c9.758-1.917 20.36-14.971 20.36-28.505z"
          />
          <path
            fill="url(#f)"
            d="M22.046 27.547l13.252 1.317-4.216 13.294c-6.024.36-7.83 7.546-12.77 7.546-2.41 0-7.951-2.276-11.084-5.75a2.887 2.887 0 01-.361-3.353c.602-.958 1.807-1.317 2.77-.718 1.567.838 3.856 1.796 5.904 1.796 4.578 0 6.385-1.916 3.253-8.384l3.252-5.748z"
          />
          <path
            fill="url(#g)"
            d="M22.046 27.547l13.252 1.317-4.216 13.294c-6.024.36-7.83 7.546-12.77 7.546-2.41 0-7.951-2.276-11.084-5.75a2.887 2.887 0 01-.361-3.353c.602-.958 1.807-1.317 2.77-.718 1.567.838 3.856 1.796 5.904 1.796 4.578 0 6.385-1.916 3.253-8.384l3.252-5.748z"
          />
          <path
            fill="url(#h)"
            d="M16.746 19.163h7.228c-.723-1.557-1.205-2.875-1.446-4.072-.602-2.875-3.975-5.869-5.782 4.072"
          />
          <path
            fill="url(#i)"
            d="M46.864 48.147l-3.012-2.995c-6.265-8.623-15.42-13.054-15.42-13.054-8.434 7.066-17.95 4.311-21.324 1.197-.362-.359-.482-.718-.362-1.197 2.29-11.258 8.434-14.612 17.59-14.013 3.011-8.384 6.264-5.15 6.987-2.036 1.084 4.192 1.566 10.3 12.408 14.731 26.384 10.78 12.53 43.236 2.41 43.236H31.443c-2.168 0-2.77-1.077-2.77-1.916 0-1.557 2.288-3.353 12.167-3.353 0 0-10.12-3.953-10.12-14.612 0-10.66 10.602-9.94 13.975-7.545l2.892 2.155"
          />
          <path
            fill="url(#j)"
            d="M24.456 18.085c-9.276-.599-15.42 2.755-17.59 14.013-.12.479.121 1.078.603 1.317 3.253 1.677 4.699-8.863 13.734-11.018 1.807-.6 3.253-2.276 3.253-4.312z"
            opacity="0.8"
          />
          <path
            fill="#FFF"
            d="M16.505 28.744c-.482-1.197-.362-2.395.723-3.233 1.084-.839 2.53-.48 3.132.479.602.958.482 2.155-.723 2.994-1.084.958-2.169.479-3.132-.24z"
            opacity="0.5"
          />
          <path
            fill="url(#k)"
            d="M17.71 25.99c-.844.598-1.085 1.557-.844 2.515.723.599 1.807.599 2.65 0 .482-.36.844-.839.964-1.437a1.243 1.243 0 00-.963-1.438c-.723-.12-1.326 0-1.808.36z"
          />
          <path
            fill="url(#l)"
            d="M17.71 25.99c-.844.598-1.085 1.557-.844 2.515.723.599 1.807.599 2.65 0 .482-.36.844-.839.964-1.437a1.243 1.243 0 00-.963-1.438c-.723-.12-1.326 0-1.808.36z"
          />
          <ellipse
            cx="17.95"
            cy="27.068"
            fill="url(#m)"
            rx=".6"
            ry=".6"
          />
          <ellipse
            cx="17.95"
            cy="27.068"
            fill="url(#m)"
            opacity="0.7"
            rx=".6"
            ry=".6"
          />
          <path
            fill="url(#n)"
            d="M16.625 53.895c.482-9.94-4.698-16.288-9.035-17.486 0 0-4.82.48-7.35 2.995 0 0 8.193 2.994 8.795 13.174 0 0 3.253-.36 7.59 1.317z"
          />
          <path
            fill="url(#o)"
            d="M43.852 30.78c-9.638-3.952-11.084-9.222-12.047-13.174 1.686 16.169 9.999 14.372 16.866 20.96 3.132 2.993 6.023 9.7 6.023 18.324l3.494 2.874c2.53-10.18.362-22.995-14.336-28.984z"
            opacity="0.8"
          />
          <path
            fill="url(#p)"
            d="M41.081 68.747s-10.12-3.953-10.12-14.612c0-10.66 10.602-9.94 13.975-7.545.362.24.723.479 1.084.838 2.41 1.916 4.578 5.03 6.024 7.665"
          />
          <path
            fill="url(#q)"
            d="M30.118 42.877c-3.253.599-8.072 5.15-8.433 10.66 7.349-.48 8.072-8.385 16.264-9.103 3.493-.36 7.108.36 10.601 4.431-1.927-10.3-.361-7.665-18.432-5.988z"
          />
          <path
            fill="url(#r)"
            d="M52.044 55.213c-5.06-9.342-11.204-11.857-15.902-11.857-6.385 0-9.638 8.024-14.818 8.024-2.41 0-7.952-2.275-11.084-5.748a2.887 2.887 0 01-.361-3.354c.602-.958 1.807-1.317 2.77-.719 1.567.839 3.856 1.797 5.904 1.797 4.216 0 5.662-9.102 13.372-9.102 6.506 0 20.119 4.311 20.119 20.959z"
          />
          <path
            fill="url(#s)"
            d="M52.044 55.213c-5.06-9.342-11.204-11.857-15.902-11.857-6.385 0-9.638 8.024-14.818 8.024-2.41 0-7.952-2.275-11.084-5.748a2.887 2.887 0 01-.361-3.354c.602-.958 1.807-1.317 2.77-.719 1.567.839 3.856 1.797 5.904 1.797 4.216 0 5.662-9.102 13.372-9.102 6.506 0 20.119 4.311 20.119 20.959z"
            opacity="0.6"
          />
          <path
            fill="url(#t)"
            d="M41.081 68.747c-10 0-12.168 1.796-12.168 3.353 0 .839.603 1.916 2.771 1.916h14.698c2.41 0 4.94-1.796 7.108-4.55l-12.409-.72z"
            opacity="0.5"
          />
          <path
            fill="url(#u)"
            d="M31.925 34.254c-7.59 0-9.156 9.102-13.372 9.102-2.048 0-4.337-.958-5.903-1.797-.964-.479-2.169-.24-2.771.719-.723 1.078-.482 2.515.361 3.354 3.253 3.353 8.795 5.748 11.084 5.748 5.18 0 8.433-8.024 14.818-8.024l-4.217-9.102z"
            opacity="0.6"
          />
          <path
            fill="url(#v)"
            d="M20.48 38.326c4.699-1.677 7.108-4.432 8.072-6.348-2.891 2.395-5.783 3.593-8.433 4.192.24.838.361 1.557.361 2.156z"
          />
          <path
            fill="#3B150D"
            d="M6.265 39.763l-1.928-2.755c-.361-.479-.964-.599-1.446-.24-.24.12-.361.36-.482.72v.478c.12-.12.241-.12.362-.24v-.119c0-.12.12-.36.24-.36.242-.239.724-.119.965.12l1.927 2.755c.12.12.12.24.12.36s-.12.239-.12.239c-.24.12-.482.12-.602-.12l-1.205-1.796c-.12-.12-.24-.12-.24 0-.121.12-.121.24 0 .24L5.06 40.84c.12.24.361.36.723.36.12 0 .361 0 .482-.12.12-.12.24-.36.361-.48-.241-.359-.241-.599-.361-.838z"
          />
        </g>
        <path
          fill="var(--logo-color)"
          d="M93.148 39.045l4.698 13.294 4.699-13.294h5.662L99.774 61.8c-1.084 3.114-1.446 5.509-5.18 5.509h-3.976v-4.79h2.289c.964 0 1.205-.48 1.446-.959l.602-1.677-7.47-20.84h5.663zm-41.804-7.546v9.222c.964-1.437 3.012-2.395 4.94-2.395 4.216 0 8.432 2.874 8.432 9.102v3.713c0 6.108-4.096 9.103-8.433 9.103-1.927 0-3.975-.959-4.939-2.396v1.916h-5.421V31.5h5.421zm22.046 0v9.222c.964-1.437 3.012-2.395 4.94-2.395 4.216 0 8.433 2.874 8.433 9.102v3.713c0 6.108-4.096 9.103-8.433 9.103-1.928 0-3.976-.959-4.94-2.396v1.916h-5.42V31.5h5.42zm-40.117 6.827c6.024 0 9.397 3.833 9.397 9.581v2.635c0 5.75-3.373 9.582-9.397 9.582-6.023 0-9.397-3.833-9.397-9.582v-2.635c0-5.748 3.374-9.58 9.397-9.58zM11.83 31.5c5.783 0 9.036 3.234 9.036 8.145 0 2.395-.844 4.43-2.53 5.748 2.048 1.438 3.252 3.713 3.252 6.228 0 5.03-3.132 8.264-9.035 8.264H.143V31.5H11.83zm21.324 11.618c-2.53 0-3.976 1.677-3.976 4.072v4.072c0 2.515 1.446 4.072 3.976 4.072 2.53 0 3.975-1.677 3.975-4.072v-4.072c0-2.515-1.445-4.072-3.975-4.072zm22.167.479c-2.048 0-3.976 1.198-3.976 4.072v3.353c0 2.875 1.928 4.073 3.976 4.073s3.975-1.198 3.975-4.073v-3.353c0-2.874-1.927-4.072-3.975-4.072zm22.167 0c-2.049 0-3.976 1.198-3.976 4.072v3.353c0 2.875 1.927 4.073 3.976 4.073 2.048 0 3.975-1.198 3.975-4.073v-3.353c0-2.874-1.927-4.072-3.975-4.072zm-64.815 4.551H5.565v6.228h7.107c2.29 0 3.374-1.078 3.374-3.114 0-2.036-1.326-3.114-3.374-3.114zm-.722-11.258H5.444v5.988h6.506c2.048 0 3.373-1.078 3.373-2.994.12-2.036-1.205-2.994-3.373-2.994z"
          transform="translate(0 6) translate(0 13.65)"
        />
        <g transform="translate(0 6) translate(193.04)">
          <path
            className={`lamp-handle ${isHandleDown ? 'down' : 'up'}`}
            fill="var(--logo-color)"
            d="M20.96 36v22.126a4.002 4.002 0 01-1 7.874 4 4 0 01-1-7.874V36h2z"
          />
          {
            isDarkTheme ? (
              <g fill="none" fillRule="evenodd" transform="translate(-321 -254)">
                <use fill="#000" filter="url(#oval-a)" xlinkHref="#oval-b" />
                <use fill="#FFD779" xlinkHref="#oval-b" />
                <use fill="#000" filter="url(#oval-c)" xlinkHref="#oval-b" />
              </g>
            ) : (
              <>
                <use fill="#000" filter="url(#w)" xlinkHref="#x" />
                <use fill="#0C0C0C" xlinkHref="#x" />
                <use fill="#000" filter="url(#y)" xlinkHref="#x" />
              </>
            )
          }
          <g fillRule="nonzero">
            <use fill="#000" filter="url(#z)" xlinkHref="#A" />
            <use fill="var(--logo-color)" xlinkHref="#A" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
