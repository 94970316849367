import React from 'react'
import PropTypes from 'prop-types'

// Icons
import arrowDouble from './icons/arrow-double.svg'
import calendar from './icons/calendar.svg'
import coin from './icons/coin.svg'
import faceId from './icons/face-id.svg'
import moneyBill from './icons/money-bill.svg'
import moon from './icons/moon.svg'
import saturation from './icons/saturation.svg'
import filter from './icons/filter.svg'
import disabledSubscription from './icons/disabled-subscription.svg'

function Icon({
  name
}) {
  return (
    <div className="icon">
      {{
        arrowDouble: <img src={arrowDouble} alt={name} />,
        calendar: <img src={calendar} alt={name} />,
        coin: <img src={coin} alt={name} />,
        faceId: <img src={faceId} alt={name} />,
        moneyBill: <img src={moneyBill} alt={name} />,
        moon: <img src={moon} alt={name} />,
        saturation: <img src={saturation} alt={name} />,
        filter: <img src={filter} alt={name} />,
        disabledSubscription: <img src={disabledSubscription} alt={name} />,
      }[name]}
    </div>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired
}

export default Icon
