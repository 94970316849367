import React from 'react'
import PropTypes from 'prop-types'

// Components
import Icon from './Icon'

function Feature({
  title,
  iconName,
  description,
  href
}) {
  return (
    <div className="feature">
      <Icon name={iconName} />
      <div className="feature-text">
        <h2>{title}</h2>
        {
          description && <p>{description}</p>
        }
        {
          href && (
            <a className="text-link" href={href} target="blank">
              Read more
            </a>
          )
        }
      </div>
    </div>
  )
}

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  description: PropTypes.string,
  href: PropTypes.string
}

export default Feature
