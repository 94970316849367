import React from 'react'
import PropTypes from 'prop-types'

// Components
import Feature from './Feature'

function NewFeatures({ data }) {
  return (
    <div className="new-features">
      <h1>New in Bobby v3.9</h1>
      {
        data.map(({
          title, iconName, href, description
        }, idx) => (
          <Feature
            key={idx}
            title={title}
            description={description}
            iconName={iconName}
            href={href}
          />
        ))
      }
    </div>
  )
}

NewFeatures.propTypes = {
  data: PropTypes.any
}

export default NewFeatures
