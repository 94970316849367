import React from 'react'
import PropTypes from 'prop-types'

function Announcement({
  title,
  label
}) {
  return (
    <div className="announcement">
      { label && (
        <span className="announcement-label">
          {label}
        </span>
      )}
      <a href="https://submit.bobbyapp.co/" className="text-link">Submit Missing Subscriptions!</a>
      <p className="small">{title}</p>
    </div>
  )
}

Announcement.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default Announcement
