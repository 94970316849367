import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'
import mitt from 'mitt'

// Theme
import { dark, light } from '../config/theme'

// Utils
import { EmitterContext } from '../utils/handleEmitter'

// Style
import '../scss/master.scss'

function Layout({ children }) {
  const [isDarkTheme, setIsDarkTheme] = useState(true)

  useEffect(() => {
    setColorScheme()

    window.___emitter.on('toggleTheme', () => {
      setIsDarkTheme(!isDarkTheme)
      window.___emitter.emit('setIsDarkTheme', isDarkTheme)
    })

    if (isDarkTheme) {
      activateMode('dark')
    } else {
      activateMode('light')
    }

    return () => (
      window.___emitter.off('*')
    )
  })

  function activateMode(mode) {
    const rootElement = document.querySelector(':root')
    const theme = mode === 'dark' ? dark : light

    return Object.keys(theme).forEach(key => (
      rootElement.style.setProperty(key, theme[key])
    ))
  }

  function setColorScheme() {
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    const isLightMode = window.matchMedia('(prefers-color-scheme: light)').matches
    const isNotSpecified = window.matchMedia('(prefers-color-scheme: no-preference)').matches
    const hasNoSupport = !activateMode('dark') && !activateMode('light') && !isNotSpecified

    window.matchMedia('(prefers-color-scheme: dark)').addListener(e => e.matches && activateMode('dark'))
    window.matchMedia('(prefers-color-scheme: light)').addListener(e => e.matches && activateMode('light'))

    if (isDarkMode) activateMode('dark')
    if (isLightMode) activateMode('light')

    if (isNotSpecified || hasNoSupport) {
      console.log('You specified no preference for a color scheme or your browser does not support it. Toggle light theme by clicking on the logo.')
      activateMode('dark')
    }
  }

  return (
    <EmitterContext.Provider value={{ events: mitt() }}>
      <div className={`page-wrapper ${isDarkTheme ? 'dark' : 'light'}`}>
        <Helmet>
          <meta charset="utf-8" />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <title>Bobby - Keep track of your subscriptions</title>
          <meta name="description" content="Bobby helps you get insights in your fixed costs. Manage your subscriptions and get notified when a bill is due." />

          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />

          <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('/favicons/apple-touch-icon.png')} />
          <link rel="icon" type="image/x-icon" sizes="any" href={withPrefix('/favicons/favicon.ico')} />
          <link rel="manifest" href={withPrefix('/favicons/site.webmanifest')} />
          <link rel="mask-icon" href={withPrefix('/favicons/safari-pinned-tab.svg')} color="#5bbad5" />

          <meta name="msapplication-TileColor" content="#0a6cff" />
          <meta name="theme-color" content="#0a6cff" />
          <meta name="msapplication-config" content="https://www.bobbyapp.co/favicons/browserconfig.xml" />

          <meta property="og:title" content="Bobby — Keep track of your subscriptions" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="www.bobbyapp.co" />
          <meta property="og:description" content="Bobby helps you get insights in your fixed costs. Manage your subscriptions and get notified when a bill is due." />
          <meta property="og:url" content="https://www.bobbyapp.co/" />
          <meta property="og:image" content="https://www.bobbyapp.co/favicons/og-image.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="625" />
          <meta property="og:image:alt" content="Bobby" />

          <meta name="twitter:card" content="app" />
          <meta name="twitter:site" content="@getbobbyapp" />
          <meta name="twitter:text:title" content="Bobby — Keep track of your subscriptions" />
          <meta name="twitter:description" content="Bobby helps you get insights in your fixed costs. Manage your subscriptions and get notified when a bill is due." />

          <meta name="twitter:app:name:iphone" content="Bobby App" />
          <meta name="twitter:app:id:iphone" content="1059152023" />

          <meta name="twitter:app:name:ipad" content="Bobby App" />
          <meta name="twitter:app:id:ipad" content="1059152023" />

          <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700" rel="stylesheet" type="text/css" />
        </Helmet>

        {children}
      </div>
    </EmitterContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}

export default Layout
